// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Sentry from '@sentry/browser';
import { Breadcrumb, BreadcrumbHint, Event } from '@sentry/types';
import get from 'lodash/fp/get';
import some from 'lodash/fp/some';
import truncate from 'lodash/fp/truncate';

import { getNodeEnv, getSentryConfig } from './getFrontendEnv';
import { getRailsEnv } from './railsEnvironment';

const blacklistUrls = [
  // Application custom
  // CKEditor
  /ckeditor.js$/,
  /\/standard-all\/plugins\/tabletools\/dialogs\/tableCell.js/,

  // Setry recommendation
  // Browser extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^[a-z]+-extension:\/\//i,

  /ao-injector.remote.js$/,

  // Tracking script
  /inspectlet.js/,
  /libs\/mixpanel/,

  // User machine
  /\/[A-Z]:\//,

  // One trust
  /otBannerSdk.js$/,

  // GTM tracking
  /gtag\/js$/,
  /gtm.js$/,

  // Adsrvr
  /up_loader\.(.*)\.js$/,
];

const ignoreErrors = [
  // Incomplete loading JS from mainapp
  /'?safeOnLoad'? is not defined/,
  /'?safeOnLoad'? is undefined/,
  /Can't find variable: '?safeOnLoad'?/,

  // Known issue on chrome iOS when opening filepicker modal
  /dialog.filepicker.io/,
  // Error from twitter widget script
  /Cannot read property 'twttr.button' of null/,

  // Known issue on chrome iOS
  /Blocked a frame with origin .* from accessing a cross-origin frame. Protocols, domains, and ports must match/,
  // Known error on Samsung device
  /document.getElementsByClassName.ToString is not a function/,
  // Issue from IE
  /Operation aborted/,
  // Network error when loading webpack chunk
  /Loading chunk [0-9]+ failed./,
  // User cancel action
  /The operation was canceled by the user./,

  // client custom error
  /myGloFrameList/,
  // https://stackoverflow.com/questions/50272371/what-is-betterjspop-error-on-chrome
  /BetterJsPop/,
  // simply a not supported error
  /NotSupportedError/,
  /vid_mate_check is not defined/,

  // Firefox < 42 error https://bugzilla.mozilla.org/show_bug.cgi?id=866372
  /internal error while computing Intl data/,
  /Error calling method on NPObject!/,

  // Intendedly duplicated reducer branch
  'Reducer [okrs/listOkrsPage] is already REGISTERED',

  // Ignore irrelevant error
  /InvalidStateError: The object is in an invalid state/,
  /InvalidStateError: InvalidStateError/,

  // Ignore noise caused by 3rd party extension: Grammarly
  /ResizeObserver loop limit exceeded/,
  // Ignore safely, this's like framedrop, no affect
  /ResizeObserver loop completed with undelivered notifications\./,

  // Network error / Offline from client side, we can't handle them
  /Failed to fetch/,
  /NetworkError when attempting to fetch resource\./,
  /The internet connection appears to be offline\./,
  /Network request failed/,
  /Could not connect to the server\./,
  /Non-Error promise rejection captured\./,

  // Ignore noise caused by Edge Mobile's Bing Chat
  /Can't find variable: msDiscoverChatAvailable/,

  // Ignore common missing feature flag errors when rolling out a new feature
  /\[4[0-9][0-9]\] Request failed: checkFeatureFlag\/.*/,
];

const ignoreUserAgents = [
  /MQQBrowser/,
  /MSIE/, // IE 9 and below
  /SamsungBrowser/,
  /YaBrowser/,
];

enum SentrySource {
  EmploymentHero = 'EH',
  KeyPay = 'KP',
}

const getSampleRate = () => {
  switch (getRailsEnv()) {
    case 'development':
      return 0.0;
    case 'staging':
      if (getNodeEnv() === 'development') {
        return 0.0;
      }
      return 0.5;
    default:
      return 1.0;
  }
};

export const customIntegrations = {
  GlobalHandlers: new Sentry.Integrations.GlobalHandlers({
    onunhandledrejection: false,
    onerror: true,
  }),
  // manually call for the filter integration
  // since the docs declare blacklistUrls option is not yet supported
  InboundFilters: new Sentry.Integrations.InboundFilters({
    blacklistUrls,
    ignoreErrors,
  }),
};

const getSentryEnv = () => {
  const hostName = window.location.hostname;

  if (hostName === 'secure.employmenthero.com') {
    return 'production';
  }

  if (hostName === 'secure.support01.employmenthero.com') {
    return 'support01';
  }

  if (hostName === 'secure.integration.employmenthero.com') {
    return 'integration';
  }

  if (hostName.includes('staging.ehrocks')) {
    return 'sandbox';
  }

  return 'unknown';
};

const getReactAppSentryReleaseVersion = () =>
  window.gon?.frontend_version || 'unknown';

const initSentry = (
  userEmail: string | undefined | null,
  initOptions = {},
  isKeyPay = false
) => {
  Sentry.init({
    dsn: getSentryConfig(),
    environment: getSentryEnv(),
    release: getReactAppSentryReleaseVersion() || 'unknown',
    blacklistUrls,
    ignoreErrors,
    shutdownTimeout: 100,
    sampleRate: getSampleRate(),
    beforeBreadcrumb: (breadcrumb: Breadcrumb, hint: BreadcrumbHint) => {
      const IGNORED_URLS = [
        'api.mixpanel.com',
        'api-js.mixpanel.com',
        'track.ehrocks.com',
      ];

      const isHttpType = breadcrumb.type === 'http';
      const url = breadcrumb?.data?.url;
      if (
        isHttpType &&
        some(ignoredUrl => url?.includes(ignoredUrl))(IGNORED_URLS)
      ) {
        return null;
      }

      // Filter console logs data since they do not give any useful info
      if (breadcrumb.category === 'console') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, ...breadcrumbWithoutData } = breadcrumb;
        return breadcrumbWithoutData;
      }

      if (breadcrumb.category === 'ui.click') {
        const label = hint.event.target.innerText;
        if (label) {
          return {
            ...breadcrumb,
            message: `${breadcrumb.message}[label="${truncate({
              length: 20,
            })(label)}"]`,
          };
        }
      }

      return breadcrumb;
    },
    beforeSend: (event: Event) =>
      some((userAgent: RegExp) =>
        userAgent.test(get('request.headers["User-Agent"]')(event))
      )(ignoreUserAgents)
        ? null
        : event,
    integrations: [
      customIntegrations.GlobalHandlers,
      customIntegrations.InboundFilters,
    ],
    ...initOptions,
  });

  Sentry.configureScope(scope => {
    scope.setLevel(Sentry.Severity.Error);
    // fallback for case where user is not yet logged in / gon is not yet set
    // we will use default user ip
    if (userEmail != null) scope.setUser({ email: userEmail });

    // Set custom tags to identify the sentry source
    scope.setTag(
      'from',
      isKeyPay ? SentrySource.KeyPay : SentrySource.EmploymentHero
    );

    if (getSentryEnv() === 'sandbox') {
      scope.setTag('node_version', '20');
    }
  });
};

export default initSentry;
